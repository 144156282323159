<script>
// HEADER
import lion from './assets/lion.png'
import lionMobile from './assets/lion-mobile.png'

// DATA SECTION
import secondLionImg from './assets/lion-2.png'
import icon from './assets/lion-icon.png'
import soundImg from './assets/lion-btn-audio.png'
import lionPic from './assets/lion-pic.png'
import dataBg from './assets/data-bg.png'
import lionSound from './assets/lion-sound.mp4'

// STAR SECTION
import star1 from './assets/star1.png'
import star2 from './assets/star2.png'
import star3 from './assets/star3.png'
import star4 from './assets/star4.png'

import customBg from './assets/data-bg-2-lion.png'

export default {
	pageView: true,
	data() {
		return {
			customBg,
			lion,
			lionMobile,
			icon,
			soundImg,
			secondLionImg,
			lionPic,
			dataBg,
			lionSound,
			dataSectionItems: [
				'El león tiene el rugido más fuerte de todos los grandes felinos. Sí, se puede escuchar hasta a 5 km de distancia. Eso es más o menos 35 a 50 cuadras; o sea si un león rugiera en un barrio de una ciudad se escucharía ¡en otros barrios también!',
				'Los leones son muy relajados y buenos para descansar: no hacen nada 20 horas al día.',
				'La salud de los leones macho se puede conocer por su melena. Mientras más amplia y oscura es, más sanos están.',
				'En los primeros tres meses de vida toman solo leche y recién al año comienzan a aprender a cazar.',
			],
			secondDataSectionItems: [
				{
					title: 'Su hogar está en...',
					text: 'África al sur del desierto del Sahara y en un pequeño lugar en el oeste de la India.',
				},
				{
					title: 'El león es...',
					text: 'un mamífero, como las personas, lo que quiere decir que toma leche de su mamá cuando nace. ',
				},
				{
					title: 'Le gusta comer...',
					text: 'carne; el león es carnívoro. No come verduras ni frutas. ',
				},
			],
			thirdDataSectionItems: [
				'Leones y leonas son muy famosos porque son animales que representan coraje, dignidad y poder. De ahí que “el rey de la selva” siempre sea un león. Hay muchos leones que han sido protagonistas de cuentos y películas a lo largo de la historia.',
				'El primero fue “El león y el ratón”, una fábula de Esopo. ',
				'Algunos fueron especialmente divertidos, como es el caso del león cobarde que aparece en “El mago de Oz”. Totalmente distinto es Aslan, el león profeta que aparece en “Las crónicas de Narnia” de C.S. Lewis.',
			],
			starItems: [
				{
					img: star1,
					text: 'Será tuya cuando sepas dónde viven los leones.',
				},
				{
					img: star2,
					text: 'La conseguirás cuando aprendas alguna de las lindas canciones que escuchaste.',
				},
				{
					img: star3,
					text: 'Toda para ti cuando puedas imitar el rugido del león.',
				},
				{
					img: star4,
					text: 'La cuarta y ¡última! Cuando termines de colorear la lámina descargable.',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? lionMobile : lion" :img-ratio="isMobile ? 0.76 : 2.17" contain />
		<DataSection
			section-title="Datitos sorprendentes"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondLionImg"
			:imgWidth="$vuetify.breakpoint.xs ? '200px' : isMobile ? '300px' : '550px'"
			:img-style="$vuetify.breakpoint.xs && 'top: 90%'"
			:sound-img="soundImg"
			:animal-sound="lionSound"
			:background="dataBg"
			class="lightpurple"
			row-class="py-16"
		/>
		<DataSection
			section-title="¿Lo sabías?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow"
			row-class="pt-15"
		/>
		<SongSection animal="leon" :custom-bg="customBg">
			<iframe
				src="https://open.spotify.com/embed/playlist/0ZUvhu6KYsbGk3nFETSqg2"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			></iframe>
		</SongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="lionPic"
			section-title="¡En la pantalla grande!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="¡Conviértete en un/a especialista en animales!"
			second-section-title="¿Has completado las 4 tareas? ¡Felicitaciones!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					Si quieres obtener tu certificado de especialista en animales deberás cumplir las consignas que
					los animales han preparado para ti. Para poder conseguir cada una de estas estrellas en el área
					de los leones, tendrás que realizar estos 4 desafíos. ¡Comencemos!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					Es hora de buscar el certificado que viene con tu libro “1, 2, 3 ¿Qué ves?” y sacar tus lápices
					de colores. Pinta la primera estrella de color amarillo. ¡Eres un/a especialista en leones!
				</div>
			</template>
		</StarsSection>
		<Home-G-Cards />
	</div>
</template>
