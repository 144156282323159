<script>
// HEADER
import lion from './assets/lion-english.jpg'
import lionMobile from './assets/lion-mobile-english.jpg'

// DATA SECTION
import secondLionImg from './assets/lion-2.png'
import icon from './assets/lion-icon.png'
import soundImg from './assets/lion-btn-audio-english.png'
import lionPic from './assets/lion-pic.png'
import dataBg from './assets/data-bg.png'
import lionSound from './assets/lion-sound.mp4'

// STAR SECTION
import star1 from './assets/star1.png'
import star2 from './assets/star2.png'
import star3 from './assets/star3.png'
import star4 from './assets/star4.png'

import customBg from './assets/data-bg-2-lion.png'

export default {
	pageView: true,
	data() {
		return {
			customBg,
			lion,
			lionMobile,
			icon,
			soundImg,
			secondLionImg,
			lionPic,
			dataBg,
			lionSound,
			dataSectionItems: [
				'The lion has the loudest roar of all big cats. Yes, it can be heard up to 5 km away. That is around 35 to 50 blocks; which means that if a lion roared in a neighborhood in a city, it would be heard in other neighborhoods too!',
				'Lions are very easy-going and good at resting: they do nothing during 20 hours a day.',
				'You can tell how healthy a male lion is by its mane. The bigger and darker it is, the healthier they are.',
				'In their first three months of life, they only drink milk. Only when they are a year old, they learn to hunt.',
			],
			secondDataSectionItems: [
				{
					title: 'Their home is in...',
					text: 'Africa, to the South of the Sahara Desert, and in a small place to the West of India.',
				},
				{
					title: 'The lion is...',
					text: 'A mammal, the same as people, which means it drinks its mother’s milk when it is born.',
				},
				{
					title: 'It likes eating...',
					text: 'Meat; the lion is a carnivore. It doesn’t eat vegetables or fruit.',
				},
			],
			thirdDataSectionItems: [
				'Lions and lionesses are very famous because they are animals which represent bravery, dignity and power. That is why “the king of the jungle” is always a lion. There are a lot of lions which have played the lead in tales and movies throughout history.',
				'The first was “The Lion and the Mouse,” a fable by Aesop.',
				'Some were especially fun, such as the coward lion that appears in “The Wizard of Oz.” The opposite can be said about Aslan, the prophet lion that appears in “The Chronicles of Narnia” by C.S Lewis.',
			],
			starItems: [
				{
					img: star1,
					text: 'It will be yours when you know where lions live',
				},
				{
					img: star2,
					text: 'You will get it when you learn one of these beautiful songs.',
				},
				{
					img: star3,
					text: 'All yours when you can copy the lion’s roar.',
				},
				{
					img: star4,
					text: 'The fourth and last! After you finish coloring the downloadable sheet.',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? lionMobile : lion" :img-ratio="isMobile ? 0.76 : 2.17" contain />
		<DataSection
			section-title="Surprising Facts"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondLionImg"
			:imgWidth="$vuetify.breakpoint.xs ? '200px' : isMobile ? '300px' : '550px'"
			:img-style="$vuetify.breakpoint.xs && 'top: 90%'"
			:sound-img="soundImg"
			:animal-sound="lionSound"
			:background="dataBg"
			class="lightpurple"
			row-class="py-16"
		/>
		<DataSection
			section-title="Did You Know It?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow"
			row-class="pt-15"
		/>
		<EnglishSongSection animal="lion" :custom-bg="customBg">
			<iframe
				src="https://open.spotify.com/embed/playlist/0ZUvhu6KYsbGk3nFETSqg2"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			></iframe>
		</EnglishSongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="lionPic"
			section-title="On the Big Screen!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="Become an Animal Expert!"
			second-section-title="Have you completed the 4 tasks? Congratulations!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					To obtain your certificate of Animal Specialist, you must comply with the tasks that the
					animals have prepared for you. To get each of the stars in the field of lions, you will have to
					rise to these 4 challenges. Let’s get started!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					It’s time to look for the certificate that comes together with your book “1 2 3 What you see?”
					and grab your color pencils. Color the first star yellow. You’re a lion specialist!
				</div>
			</template>
		</StarsSection>
	</div>
</template>
