<script>
// HEADER
import ant from './assets/ant.png'
import antMobile from './assets/ant-mobile.png'

// DATA SECTION
import secondAntImg from './assets/ant-2.png'
import icon from './assets/ant-icon.png'
import antPic from './assets/ant-pic.png'

// STAR SECTION
import star1 from './assets/ant-star1.png'
import star2 from './assets/ant-star2.png'
import star3 from './assets/ant-star3.png'
import star4 from './assets/ant-star4.png'
import dataBg from './assets/data-bg.png'

export default {
	pageView: true,
	data() {
		return {
			ant,
			antMobile,
			secondAntImg,
			icon,
			antPic,
			dataBg,
			dataSectionItems: [
				'Las hormigas cooperan entre sí y tienen trabajos y hasta formas del cuerpo especializadas en lo que hacen: las que cuidan a las hormigas bebé, las que salen a cazar, las guerreras que defienden el nido, las que lo limpian. Actúan tan coordinadas que algunos piensan que son un “superorganismo”: un animal hecho de animales.',
				'¡Algunos variedades de hormigas tienen profesores individuales! Sí, una hormiga que no sabe cómo cortar hojitas sale con una que sabe y aprende mientras la experta le muestra cómo hacerlo.',
				'Las hormigas ¡pueden ser engañadas! Hay una oruga que emite un olor que hace creer a las hormigas que es un bebé de hormiga, así ellas la llevan a la colonia y la alimentan como si fuera una… hasta que se convierte en mariposa y… ¡comida hecha, amistad deshecha!.',
			],

			secondDataSectionItems: [
				{
					title: 'Su hogar está en...',
					text: 'Todos los continentes, a excepción de la Antártida y unas pocas islas muy remotas.',
				},
				{
					title: 'La hormiga es...',
					text: 'Un insecto.',
				},
				{
					title: 'Le gusta comer...',
					text: 'Otros insectos, semillas, néctar de flores y hasta hongos que ella misma cultiva. Por eso es omnívora.',
				},
			],
			thirdDataSectionItems: [
				'La película más famosa sobre hormigas es “Hormigaz” (“Antz”) donde una hormiga obrera llamada Z quiere conquistar el corazón de una princesa hormiga llamada Bala. La verdad, no es tan fácil, así que pasa muchas aventuras en busca de lograrlo. ',
				'En Estados Unidos hay una serie muy entretenida en que un oso hormiguero (que viven de comerse a las hormigas) se la pasa persiguiendo a una hormiguita flaquita, débil, pero también activa y peleadora.',
			],
			starItems: [
				{
					img: star1,
					text: 'Toda para ti cuando recuerdes un datito sorprendente sobre las hormigas.',
				},
				{
					img: star2,
					text: 'La conseguirás cuando aprendas alguna de las lindas canciones que escuchaste.',
				},
				{
					img: star3,
					text: 'Será tuya si sabes qué le gusta comer a las hormigas.',
				},
				{
					img: star4,
					text: 'La cuarta y ¡última! Cuando termines de colorear la lámina descargable.',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? antMobile : ant" :img-ratio="isMobile ? 0.76 : 2.17" />
		<DataSection
			section-title="Datitos sorprendentes"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondAntImg"
			:imgWidth="$vuetify.breakpoint.xs ? '250px' : isMobile ? '300px' : '600px'"
			:img-style="$vuetify.breakpoint.xs ? 'top: 90%' : isMobile ? 'top: 85%' : 'top: 65%'"
			:background="dataBg"
			class="lightpurple"
		/>
		<DataSection
			section-title="¿Lo sabías?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow pt-16"
		/>
		<SongSection animal="hormiga">
			<iframe
				src="https://open.spotify.com/embed/playlist/3tPHELWWFX6TPzgFpQHBmP"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			></iframe>
		</SongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="antPic"
			section-title="¡En la pantalla grande!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="¡Conviértete en un/a especialista en animales!"
			second-section-title="¿Has completado las 4 tareas? ¡Felicitaciones!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					Si quieres obtener tu certificado de especialista en animales deberás cumplir las consignas que
					los animales han preparado para ti. Para poder conseguir cada una de estas estrellas en el área
					de las hormigas, tendrás que realizar estos 4 desafíos. ¡Comencemos!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					Es hora de buscar el certificado que viene con tu libro “1, 2, 3 ¿Qué ves?” y sacar tus lápices
					de colores. Pinta la quinta estrella de color violeta. ¡Eres un/a especialista en hormigas!
				</div>
			</template>
		</StarsSection>
		<Home-G-Cards />
	</div>
</template>
