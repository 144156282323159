<script>
import heart from '../assets/star-section/heart.png'
import weasel from '../assets/star-section/weasel.png'

export default {
	props: {
		starItems: [],
		sectionTitle: String,
		secondSectionTitle: String,
	},
	data() {
		return { weasel, heart }
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>

<template>
	<div style="position: relative">
		<Container class="d-flex flex-column align-center text-center py-15" :fluid="isMobile">
			<div class="font-4 font-weight-bold mt-15 mb-8 px-16 mx-sm-16 px-3" style="z-index: 12">
				{{ sectionTitle }}
			</div>
			<div style="max-width: 900px" class="px-3">
				<slot name="first-text"></slot>
			</div>
			<div class="d-flex justify-space-between align-start my-15 px-3 w100" style="max-width: 900px">
				<div v-for="(item, i) of starItems" :key="i" class="d-flex flex-column align-center text-center">
					<Media
						:src="item.img"
						:alt="`star-${i}`"
						contain
						max-width="170px"
						max-height="170px"
						width="20vw"
					/>
					<div style="max-width: 125px" class="mt-5 font-0 px-1">{{ item.text }}</div>
				</div>
			</div>
			<div class="font-4 font-weight-bold mt-10 mb-3 px-3">
				{{ secondSectionTitle }}
			</div>
			<div class="px-3">
				<slot name="second-text"></slot>
			</div>
		</Container>
		<Media :src="heart" width="43px" contain class="heart d-none d-md-block" />
		<Media
			:src="weasel"
			:width="isMobile ? '125px' : '200px'"
			contain
			:class="isMobile ? 'weasel-mb' : 'weasel'"
		/>
	</div>
</template>
<style scoped>
.heart {
	position: absolute;
	right: 10px;
	top: 10px;
}
.weasel {
	position: absolute;
	left: 0;
	top: -45px;
}
.weasel-mb {
	position: absolute;
	left: 0;
	top: -28px;
}
</style>
