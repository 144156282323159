<script>
// HEADER
import ant from './assets/ant-portuguese.jpg'
import antMobile from './assets/ant-mobile-portuguese.jpg'

// DATA SECTION
import secondAntImg from './assets/ant-2.png'
import icon from './assets/ant-icon.png'
import antPic from './assets/ant-pic.png'

// STAR SECTION
import star1 from './assets/ant-star1.png'
import star2 from './assets/ant-star2.png'
import star3 from './assets/ant-star3.png'
import star4 from './assets/ant-star4.png'
import dataBg from './assets/data-bg.png'

export default {
	pageView: true,
	data() {
		return {
			ant,
			antMobile,
			secondAntImg,
			icon,
			antPic,
			dataBg,
			dataSectionItems: [
				'As formigas cooperam entre si e têm trabalhos e, até formas do corpo especializadas no que fazem: as que cuidam das formigas bebê, as que saem para caçar, as guerreiras que defendem o ninho, as que o limpam. Agem com tanta coordenação que alguns pensam que são um “superorganismo”: um animal feito de animais.',
				'Algumas variedades de formigas têm professores individuais! É sim, uma formiga que não sabe como cortar folhinhas sai com uma que sabe e aprende enquanto a especialista lhe mostra como fazer.',
				'As formigas podem ser enganadas. Existe uma lagarta que emite um cheiro que faz crer às formigas que é um bebê de formiga, assim elas a levam na colônia e a alimentam como se fosse uma… até que vira borboleta e… merenda feita, companhia desfeita!',
			],

			secondDataSectionItems: [
				{
					title: 'Seu lar está em...',
					text: 'Todos os continentes, com exceção da Antártida e umas poucas ilhas muito remotas.',
				},
				{
					title: 'A formiga é...',
					text: 'Um inseto.',
				},
				{
					title: 'Gosta de comer...',
					text: 'Outros insetos, sementes, néctar de flores e até fungos que ela mesma cultiva. Por isso é onívora. ',
				},
			],
			thirdDataSectionItems: [
				'O filme mais famoso sobre formigas é “Formiguinha Z” (“Antz”) na qual uma formiga operária chamada Z quer conquistar o coração de uma princesa formiga chamada Bala. Na realidade, não é tão simples, e por isso que passa muitas aventuras à procura de consegui-lo.',
				'Nos Estados Unidos há uma série muito divertida em que um urso-formigueiro (que vivem de comer as formigas) está sempre perseguindo uma formiguinha magrinha, fraca, mas também ativa e briguenta.',
			],
			starItems: [
				{
					img: star1,
					text: 'Toda para você quando lembrar de um dadinho surpreendente sobre as formigas.',
				},
				{
					img: star2,
					text: 'A conseguirá quando aprender alguma das lindas músicas ouvidas.',
				},
				{
					img: star3,
					text: 'Será sua se souber o que gostam de comer as formigas.',
				},
				{
					img: star4,
					text: 'A quarta e última! Quando acabar de colorir o desenho descarregável.',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? antMobile : ant" :img-ratio="isMobile ? 0.76 : 2.17" />
		<DataSection
			section-title="Dados surpreendentes"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondAntImg"
			:imgWidth="$vuetify.breakpoint.xs ? '250px' : isMobile ? '300px' : '600px'"
			:img-style="$vuetify.breakpoint.xs ? 'top: 90%' : isMobile ? 'top: 85%' : 'top: 65%'"
			:background="dataBg"
			class="lightpurple"
		/>
		<DataSection
			section-title="Você sabia?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow pt-16"
		/>
		<PortugueseSongSection animal="formiga">
			<iframe
				src="https://open.spotify.com/embed/playlist/3tPHELWWFX6TPzgFpQHBmP"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			></iframe>
		</PortugueseSongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="antPic"
			section-title="Na tela grande!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="Torne-se um/a especialista em animais!"
			second-section-title="Já completou as 4 tarefas? Parabéns!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					Se quiser obter seu certificado de especialista em animais deverá cumprir as instruções que os
					animais têm preparado para você. Para poder conseguir cada uma dessas estrelas na área das
					formigas, terá que realizar estes 4 desafios. Vamos lá!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					É hora de procurar o certificado que acompanha seu livro “1,2,3 O que vê? e pegar seus lápis de
					cor. Pinte a quinta estrela na cor roxa. Você é um/a especialista em formigas!
				</div>
			</template>
		</StarsSection>
	</div>
</template>
