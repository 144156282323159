<script>
// HEADER
import octopus from './assets/octopus-portuguese.jpg'
import octopusMobile from './assets/octopus-mobile-portuguese.jpg'

// DATA SECTION
import secondOctopusImg from './assets/octopus-2.png'
import icon from './assets/octopus-icon.png'
import octopusPic from './assets/octopus-pic.png'

// STAR SECTION
import star1 from './assets/octopus-star1.png'
import star2 from './assets/octopus-star2.png'
import star3 from './assets/octopus-star3.png'
import star4 from './assets/octopus-star4.png'
import dataBg from './assets/data-bg.png'

import customBg from './assets/data-bg-2-octopus.png'

export default {
	pageView: true,
	data() {
		return {
			customBg,
			octopus,
			octopusMobile,
			secondOctopusImg,
			icon,
			octopusPic,
			dataBg,
			dataSectionItems: [
				'O polvo tem oito pernas. Imagine as coisas que vocês fariam se tivessem essas oito pernas e pudessem usá-las também como braços!',
				'Os polvos são muito bons para se disfarçarem: como são tão moles, e não têm ossos, podem pegar a forma e cor de outros animais. Assim, se fazem passar  por serpentes marinhas, enguias elétricas e peixes-leões. Então, seus inimigos ficam assustados e vão embora.',
				'Cada polvo tem uma bolsinha no seu corpo cheio de tinta. Quando sentem medo, a disparam de trás deles e fogem nadando muito rápido.',
				'Existem dois tipos de polvo que podem andar como nós! Usam duas de suas pernas, enquanto que as outras seis as movimentam como algas para fazer crer aos peixes que são plantas submarinhas.',
			],
			secondDataSectionItems: [
				{
					title: 'Seu lar está em...',
					text: 'Todos os mares, das águas superficiais até águas muito profundas. Mas não em lagos ou rios.',
				},
				{
					title: 'O polvo é...',
					text: 'Um molusco, igual que os caracóis e as ostras.',
				},
				{
					title: 'Gosta de comer...',
					text: 'Amêijoas, caranguejos, centollas e outros crustáceos. Alguns polvos comem camarões e peixes. Por isso é carnívoro.',
				},
			],
			thirdDataSectionItems: [
				'As pessoas, quando crescem, sentem medo dos animais que são diferentes. Em especial, se perceberem de que são inteligentes. Isso acontece com o polvo: é apresentado como “mau” nas séries e filmes. Por exemplo, em “Os pinguins de Madagascar”, o vilão é Dave, um polvo ciumento porque os pinguins tiraram seu  protagonismo no zoológico.',
				'Na vida real há uma história muito linda. Um senhor que estava triste, sem conseguir falar muito nem dizer como se sentia, ficou amigo de um polvo (na realidade era uma polvo fêmea), mas amigo de verdade! Aí, debaixo do mar. E graças a isso, ele melhorou e depois fez um filme para contar a história. Chama-se “Professor Polvo”.',
			],
			starItems: [
				{
					img: star1,
					text: 'A conseguirá quando colorir por completo o desenho descarregável.',
				},
				{
					img: star2,
					text: 'Será sua quando souber onde habitam os polvos.',
				},
				{
					img: star3,
					text: 'A conseguirá quando aprender alguma destas lindas músicas que ouviu.',
				},
				{
					img: star4,
					text: 'A quarta e última! Quando puder imitar com seus braços o movimento de seus tentáculos (ou braços).',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? octopusMobile : octopus" :img-ratio="isMobile ? 0.76 : 2.17" />
		<DataSection
			section-title="Dados surpreendentes"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondOctopusImg"
			:imgWidth="$vuetify.breakpoint.xs ? '225px' : isMobile ? '300px' : '600px'"
			:img-style="$vuetify.breakpoint.xs ? 'top: 90%' : isMobile ? 'top: 80%' : 'top: 55%'"
			:background="dataBg"
			class="lightpurple"
		/>
		<DataSection
			section-title="Você sabia?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow pt-16"
		/>
		<PortugueseSongSection animal="polvo" :custom-bg="customBg">
			<iframe
				src="https://open.spotify.com/embed/playlist/6tq5gmi1NaziKwDaYCV95k"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			></iframe>
		</PortugueseSongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="octopusPic"
			section-title="Na tela grande!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="Torne-se um/a especialista em animais!"
			second-section-title="Já completou as 4 tarefas? Parabéns!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					Se quiser obter seu certificado de especialista em animais deverá cumprir as instruções que os
					animais têm preparado para você. Para poder conseguir cada uma dessas estrelas na área dos
					polvos, terá que realizar estes 4 desafios. Vamos lá!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					É hora de procurar o certificado que acompanha seu livro “1,2,3 O que vê? e pegar seus lápis de
					cor. Pinte a terceira estrela na cor azul-celeste. Você é um/a especialista em polvos!
				</div>
			</template>
		</StarsSection>
	</div>
</template>
