<script>
// HEADER
import octopus from './assets/octopus-english.jpg'
import octopusMobile from './assets/octopus-mobile-english.jpg'

// DATA SECTION
import secondOctopusImg from './assets/octopus-2.png'
import icon from './assets/octopus-icon.png'
import octopusPic from './assets/octopus-pic.png'

// STAR SECTION
import star1 from './assets/octopus-star1.png'
import star2 from './assets/octopus-star2.png'
import star3 from './assets/octopus-star3.png'
import star4 from './assets/octopus-star4.png'
import dataBg from './assets/data-bg.png'

import customBg from './assets/data-bg-2-octopus.png'

export default {
	pageView: true,
	data() {
		return {
			customBg,
			octopus,
			octopusMobile,
			secondOctopusImg,
			icon,
			octopusPic,
			dataBg,
			dataSectionItems: [
				'Octopuses have eight legs. Imagine the things you would do if you had those eight legs and you could use them as arms too!',
				'Octopuses are very good at disguising: as they are very soft and they have no bones, they can adopt the shape and color of other animals. That way they can pretend to be sea snakes, electric eels and lionfish. So, their enemies get frightened and they leave.',
				'Each octopus has a little bag inside its body which is full of ink. When they are scared, they release it behind them and they swim away very fast.',
				'There are two types of octopus which can walk like us! They use two of their legs, while they move the other six as if they were algae to make other fish believe they are underwater plants.',
			],
			secondDataSectionItems: [
				{
					title: 'Their home is in...',
					text: 'Every sea, from shallow waters to very deep waters. But not in lakes or rivers.',
				},
				{
					title: 'The octopus is...',
					text: 'A mollusk, like snails and oysters.',
				},
				{
					title: 'It likes eating...',
					text: 'Clams, crabs, spider crabs and other crustaceans. Some octopuses eat prawns and fish. That is why the octopus is a carnivore',
				},
			],
			thirdDataSectionItems: [
				'People, as they grow, are afraid of animals which are different. Especially, if they realize they are intelligent. That is what happens with the octopus: it is shown as the “bad” character in series and movies. For example, in “Penguins of Madagascar”, the villain is Dave, an octopus which is jealous because penguins stole the spotlight in the zoo.',
				'In real life, there is a beautiful story. A man who was sad, unable to talk a lot and to say how he felt, became friend of an octopus (actually, it was a female octopus), and they were really close friends! There, under the sea. And thanks to that friendship, he got better and then he made a movie to tell the story. The name is “My Octopus Teacher”.',
			],
			starItems: [
				{
					img: star1,
					text: 'You will have it when you have fully colored the downloadable sheet.',
				},
				{
					img: star2,
					text: 'It will be yours when you know where octopuses live.',
				},
				{
					img: star3,
					text: 'You’ll get it when you learn one of these beautiful songs.',
				},
				{
					img: star4,
					text: 'The fourth and last! When you can imitate with your arms the movement of its tentacles (or arms).',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? octopusMobile : octopus" :img-ratio="isMobile ? 0.76 : 2.17" />
		<DataSection
			section-title="Surprising Facts"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondOctopusImg"
			:imgWidth="$vuetify.breakpoint.xs ? '225px' : isMobile ? '300px' : '600px'"
			:img-style="$vuetify.breakpoint.xs ? 'top: 90%' : isMobile ? 'top: 80%' : 'top: 55%'"
			:background="dataBg"
			class="lightpurple"
		/>
		<DataSection
			section-title="Did You Know It?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow pt-16"
		/>
		<EnglishSongSection animal="octopus" :custom-bg="customBg">
			<iframe
				src="https://open.spotify.com/embed/playlist/6tq5gmi1NaziKwDaYCV95k"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			></iframe>
		</EnglishSongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="octopusPic"
			section-title="On the Big Screen!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="Become an Animal Expert!"
			second-section-title="Have you completed the 4 tasks? Congratulations!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					To obtain your certificate of Animal Specialist, you must comply with the tasks that the
					animals have prepared for you. To get each of the stars in the field of octopuses, you will
					have to rise to these 4 challenges. Let’s get started!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					It’s time to look for the certificate that comes together with your book “1 2 3 What do you
					see?” and grab your color pencils. Color the third star light blue. You’re an octopus
					specialist!
				</div>
			</template>
		</StarsSection>
	</div>
</template>
