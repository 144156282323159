<script>
// HEADER
import chicken from './assets/chicken.png'
import chickenMobile from './assets/chicken-mobile.png'

// DATA SECTION
import secondChickenImg from './assets/chicken-2.png'
import icon from './assets/chicken-icon.png'
import soundImg from './assets/chicken-btn-audio.png'
import chickenSound from './assets/chicken-sound.mp4'
import chickenPic from './assets/chicken-pic.png'

// STAR SECTION
import star1 from './assets/chicken-star1.png'
import star2 from './assets/chicken-star2.png'
import star3 from './assets/chicken-star3.png'
import star4 from './assets/chicken-star4.png'
import dataBg from './assets/data-bg.png'

export default {
	pageView: true,
	data() {
		return {
			chicken,
			chickenMobile,
			secondChickenImg,
			icon,
			soundImg,
			chickenSound,
			chickenPic,
			dataBg,
			dataSectionItems: [
				'Si las dejan tranquilas y no se los roban, una gallina pone hasta 12 huevos seguidos, se detiene, y se sienta arriba de ellos y ¡a empollarlos se ha dicho!',
				'Algunos tipos de gallinas son tan maternales que no tienen problema en incubar huevos de patos, gansos, codornices, pavos y hasta faisanes. ¡Y después los crían!',
				'Algunos gallos pueden ser muy “caballerosos”; si encuentran comida llama a las gallinas para que coman ellas primero. Canta y se pone a recoger y tirar comida por todas partes, como si fuera un chef muy orgulloso de sus platos.',
				'Hay tres gallinas o pollos por cada persona en el planeta. Son las aves más abundantes del mundo: 23.000 millones, más o menos.',
			],

			secondDataSectionItems: [
				{
					title: 'Su hogar está en...',
					text: 'Todos los continentes, menos en la Antártida.',
				},
				{
					title: 'La gallina es...',
					text: 'Un ave: nace de un huevo, está cubierta de plumas y puede volar, bueno, en realidad dar unos saltos espectaculares… si la dejan.',
				},
				{
					title: 'Le gusta comer...',
					text: '¡Sorpresa! Las gallinas son omnívoras como las personas. Normalmente, comen granos e insectos, pero pueden tragarse lagartijas y hasta ratoncitos sin hacerse problema.',
				},
			],
			thirdDataSectionItems: [
				'Habiendo tantas gallinas y pollitos en el mundo es imposible que no aparezcan en muchas películas. Quizá las dos más famosas son “Pollitos en fuga”, donde escapan de un propietario malvado, y “Chicken Little”, en la que un pollito se llena de coraje para salvar a su familia, amigos y el mundo de una invasión extraterrestre.',
				'Tanto o más famosas son las aventuras del gallo Claudio (de “Looney Tunes”) con el pollito hijo de su vecina en el gallinero.',
			],
			starItems: [
				{
					img: star1,
					text: 'La tendrás cuando puedas imitar el sonido de la gallina.',
				},
				{
					img: star2,
					text: '¿Sabes dónde viven las gallinas? ¡Si es así, la segunda estrella es para ti!',
				},
				{
					img: star3,
					text: 'La conseguirás cuando aprendas alguna de las lindas canciones que escuchaste.',
				},
				{
					img: star4,
					text: 'La cuarta y ¡última! La conseguirás cuando termines de colorear la lámina descargable.',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? chickenMobile : chicken" :img-ratio="isMobile ? 0.76 : 2.17" />
		<DataSection
			section-title="Datitos sorprendentes"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondChickenImg"
			:sound-img="soundImg"
			:animal-sound="chickenSound"
			:imgWidth="$vuetify.breakpoint.xs ? '200px' : isMobile ? '300px' : '600px'"
			:img-style="$vuetify.breakpoint.xs ? 'top: 92%' : isMobile ? 'top: 85%' : 'top: 70%'"
			:background="dataBg"
			class="lightpurple py-16"
		/>
		<DataSection
			section-title="¿Lo sabías?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow pt-16"
		/>
		<SongSection animal="gallina">
			<iframe
				src="https://open.spotify.com/embed/playlist/0jPA7SuavK3cBWq2TgwG9p"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			></iframe>
		</SongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="chickenPic"
			section-title="¡En la pantalla grande!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="¡Conviértete en un/a especialista en animales!"
			second-section-title="¿Has completado las 4 tareas? ¡Felicitaciones!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					Si quieres obtener tu certificado de especialista en animales deberás cumplir las consignas que
					los animales han preparado para ti. Para poder conseguir cada una de estas estrellas en el área
					de las gallinas, tendrás que realizar estos 4 desafíos. ¡Comencemos!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					Es hora de buscar el certificado que viene con tu libro “1, 2, 3 ¿Qué ves?” y sacar tus lápices
					de colores. Pinta la cuarta estrella de color naranja. ¡Eres un/a especialista en gallinas!
				</div>
			</template>
		</StarsSection>
		<Home-G-Cards />
	</div>
</template>
