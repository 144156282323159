<script>
import background from '../assets/data-second-bg.png'
import tree from '../assets/animal-pic-section/tree.png'
import mosquito from '../assets/animal-pic-section/mosquito.png'
import plant from '../assets/animal-pic-section/plant.png'
import flower from '../assets/animal-pic-section/flower.png'
import triangles from '../assets/animal-pic-section/triangles.png'

export default {
	props: {
		textItems: [],
		AnimalImg: String,
		sectionTitle: String,
	},
	data() {
		return {
			background,
			tree,
			mosquito,
			plant,
			flower,
			triangles,
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="darkorange" style="position: relative">
		<Container class="white--text">
			<v-row align="center">
				<v-col cols="12" md="6" class="d-flex justify-center">
					<Media :src="AnimalImg" class="animal-img" :max-width="isMobile ? '400px' : '500px'" contain />
				</v-col>
				<v-col cols="12" md="6" style="z-index: 12" class="pr-16">
					<div class="mb-10">
						<div class="font-10 font-weight-bold number">.03</div>
						<div class="font-4 font-weight-bold mt-2">{{ sectionTitle }}</div>
					</div>
					<div
						v-for="(item, i) of textItems"
						:key="`dataSectionItems-${i}`"
						class="d-flex align-start mb-8"
					>
						<span>{{ item }}</span>
					</div>
				</v-col>
			</v-row>
		</Container>
		<Media :src="flower" width="70px" contain class="flower" />
		<Media :src="mosquito" width="90px" contain class="mosquito" />
		<Media :src="plant" width="80px" contain class="plant" />
		<Media :src="tree" width="90px" contain class="tree" />
		<Media :src="triangles" width="200" contain :class="isMobile ? 'triangles-mb' : 'triangles'" />
	</div>
</template>

<style scoped>
.animal-img {
	transform: translateY(-50px);
	z-index: 10;
}
.flower {
	position: absolute;
	bottom: -10px;
	right: 100px;
}
.tree {
	position: absolute;
	bottom: 0px;
	right: 0px;
}
.mosquito {
	position: absolute;
	bottom: 50%;
	right: 25px;
}
.plant {
	position: absolute;
	bottom: 0px;
	right: 25px;
}
.triangles {
	position: absolute;
	bottom: 20%;
	left: 25px;
}
.triangles-mb {
	position: absolute;
	bottom: 50%;
	left: 25px;
}
</style>
