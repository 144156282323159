<script>
export default {
	props: {
		img: String,
		contain: Boolean,
		imgRatio: Number,
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="header-container">
		<Container v-if="contain" :fluid="isMobile">
			<Media :src="img" alt="animal Header" :aspect-ratio="imgRatio" eager />
		</Container>
		<Media :src="img" alt="animal Header" :aspect-ratio="imgRatio" v-else eager />
	</div>
</template>
<style>
.header-container {
	background-image: linear-gradient(to bottom, #fef5eb 40%, #fbdfba);
}
</style>
