<script>
import playlist from '../assets/btn-portuguesse-playlist.png'
import download from '../assets/btn-portuguesse-download.png'
import bg from '../assets/data-bg-2.png'

export default {
	data() {
		return {
			playlist,
			download,
			frame: false,
			bg,
		}
	},
	props: {
		animal: String,
		customBg: String,
	},
	methods: {
		downloadFile() {
			let { baseUrl } = this.$shopApi.defaults()
			window.location.href = `${baseUrl}/download-pdf/${this.animal}`
		},
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="py-15 lightpurple" style="position: relative">
		<Media :src="customBg ? customBg : bg" height="100%" style="position: absolute" position="top center" />
		<Container class="d-flex justify-center">
			<div style="width: 750px; z-index: 10">
				<div class="font-8 font-weight-bold text-start d-flex align-end">
					<Media
						:src="playlist"
						contain
						:max-width="isMobile ? '150px' : '200px'"
						@click="frame = !frame"
						class="pointer"
					/>
					<span>A cantar!</span>
				</div>
				<div class="d-flex flex-column flex-sm-row align-center align-sm-top justify-end">
					<div class="lightorange pa-3 mt-5" v-show="frame">
						<slot></slot>
					</div>
					<div class="d-flex">
						<div style="max-width: 200px" class="mt-6 mt-sm-3 ml-5">
							<div class="font-2 font-weight-bold mb-2">E COLORIR!</div>
							<div>Clique aqui e faça download de um desenho divertido para colorir.</div>
						</div>
						<Media
							:src="download"
							contain
							max-width="180px"
							max-height="180px"
							class="pointer"
							@click="downloadFile"
						/>
					</div>
				</div>
			</div>
		</Container>
	</div>
</template>
