<script>
// HEADER
import lion from './assets/lion-portuguese.jpg'
import lionMobile from './assets/lion-mobile-portuguese.jpg'

// DATA SECTION
import secondLionImg from './assets/lion-2.png'
import icon from './assets/lion-icon.png'
import soundImg from './assets/lion-btn-audio-portuguesse.png'
import lionPic from './assets/lion-pic.png'
import dataBg from './assets/data-bg.png'
import lionSound from './assets/lion-sound.mp4'

// STAR SECTION
import star1 from './assets/star1.png'
import star2 from './assets/star2.png'
import star3 from './assets/star3.png'
import star4 from './assets/star4.png'

import customBg from './assets/data-bg-2-lion.png'

export default {
	pageView: true,
	data() {
		return {
			customBg,
			lion,
			lionMobile,
			icon,
			soundImg,
			secondLionImg,
			lionPic,
			dataBg,
			lionSound,
			dataSectionItems: [
				'O leão tem o rugido mais forte de todos os grandes felinos. É sim, pode-se ouvir até a 5 km de distância. Isso são mais ou menos 35 a 50 quarteirões; ou seja, se um leão rugisse em um bairro de uma cidade seria ouvido em outros bairros também!',
				'Os leões são muito relaxados e bons para descansar: não fazem nada 20 horas ao dia.',
				'A saúde dos leões machos pode-se conhecer por sua cabeleira. Quanto mais ampla e escura for, mais sadios estão.',
				'Nos primeiros três meses de vida tomam só leite e só quando completam um ano começam a aprender a caçar.',
			],
			secondDataSectionItems: [
				{
					title: 'Seu lar está na...',
					text: 'África ao sul do deserto de Saara e em um lugar pequeno no oeste da Índia.',
				},
				{
					title: 'O leão é...',
					text: 'Um mamífero, como as pessoas, o que quer dizer que toma leite da sua mamãe quando nasce. ',
				},
				{
					title: 'Gosta de comer...',
					text: 'Carne; o leão é carnívoro. Não come verduras nem frutas.',
				},
			],
			thirdDataSectionItems: [
				'Leões e leoas são muito famosos porque são animais que representam coragem, dignidade e poder. Daí que “o rei da selva” seja sempre um leão. Há muitos leões que têm sido protagonistas de contos e filmes ao longo da história.',
				'O primeiro foi “O leão e o rato”, uma fábula de Esopo.',
				'Alguns foram especialmente divertidos, como é o caso do leão covarde que aparece em “O mágico de Oz”. Totalmente diferente é Aslam, o leão profeta que aparece em “As crônicas de Nárnia” de C.S. Lewis.',
			],
			starItems: [
				{
					img: star1,
					text: 'Será sua quando você souber onde moram os leões.',
				},
				{
					img: star2,
					text: 'Você a conseguirá quando aprender alguma das lindas músicas que ouviu!',
				},
				{
					img: star3,
					text: 'Toda para você quando puder imitar o rugido do leão.',
				},
				{
					img: star4,
					text: 'A quarta e última! Quando acabar de colorir o desenho descarregável.',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? lionMobile : lion" :img-ratio="isMobile ? 0.76 : 2.17" contain />
		<DataSection
			section-title="Dados surpreendentes"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondLionImg"
			:imgWidth="$vuetify.breakpoint.xs ? '200px' : isMobile ? '300px' : '550px'"
			:img-style="$vuetify.breakpoint.xs && 'top: 90%'"
			:sound-img="soundImg"
			:animal-sound="lionSound"
			:background="dataBg"
			class="lightpurple"
			row-class="py-16"
		/>
		<DataSection
			section-title="Você sabia?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow"
			row-class="pt-15"
		/>
		<PortugueseSongSection animal="leao" :custom-bg="customBg">
			<iframe
				src="https://open.spotify.com/embed/playlist/0ZUvhu6KYsbGk3nFETSqg2"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			></iframe>
		</PortugueseSongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="lionPic"
			section-title="Na tela grande!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="Torne-se um/a especialista em animais!"
			second-section-title="Já completou as 4 tarefas? Parabéns!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					Se quiser obter seu certificado de especialista em animais deverá cumprir as instruções que os
					animais têm preparado para você. Para poder conseguir cada uma dessas estrelas na área dos
					leões, terá que realizar estes 4 desafios. Vamos lá!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					É hora de procurar o certificado que acompanha seu livro “1,2,3 O que vê? e pegar seus lápis de
					cor. Pinte a primeira estrela na cor amarela. Você é um/a especialista em leões!
				</div>
			</template>
		</StarsSection>
	</div>
</template>
