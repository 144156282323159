<script>
// HEADER
import chicken from './assets/chicken-portuguese.jpg'
import chickenMobile from './assets/chicken-mobile-portuguese.jpg'

// DATA SECTION
import secondChickenImg from './assets/chicken-2.png'
import icon from './assets/chicken-icon.png'
import soundImg from './assets/chicken-btn-audio-portuguesse.png'
import chickenSound from './assets/chicken-sound.mp4'
import chickenPic from './assets/chicken-pic.png'

// STAR SECTION
import star1 from './assets/chicken-star1.png'
import star2 from './assets/chicken-star2.png'
import star3 from './assets/chicken-star3.png'
import star4 from './assets/chicken-star4.png'
import dataBg from './assets/data-bg.png'

export default {
	pageView: true,
	data() {
		return {
			chicken,
			chickenMobile,
			secondChickenImg,
			icon,
			soundImg,
			chickenSound,
			chickenPic,
			dataBg,
			dataSectionItems: [
				'Quando ficam tranquilas e não lhes são roubados, uma galinha bota até 12 ovos seguidos, aí para, e senta no ninho para chocá-los!',
				'Alguns tipos de galinhas são tão maternais que não têm problema em chocar ovos de patos, gansos, codornas, perus e até faisões. E depois os criam!',
				'Alguns galos podem ser muito “cavalheirescos”; quando acham comida chamam às galinhas para que elas comam primeiro. Canta enquanto recolhe e joga comida por toda parte, como se fosse um chef muito orgulhoso de seus pratos.',
				'Há três galinhas ou frangos por cada pessoa no planeta. São as aves mais abundantes do mundo: 23.000 milhões, mais ou menos.',
			],

			secondDataSectionItems: [
				{
					title: 'Seu lar está em...',
					text: 'Todos os continentes, menos na Antártida.',
				},
				{
					title: 'A galinha é...',
					text: 'Uma ave: nasce de um ovo, está coberta de penas e pode voar, bem, na verdade da uns pulos espetaculares… se a deixarem.',
				},
				{
					title: 'Gosta de comer...',
					text: 'Surpresa! As galinhas são onívoras como as pessoas. Normalmente, comem grãos e insetos, mas podem engolir lagartixas e até ratinhos sem ter problema',
				},
			],
			thirdDataSectionItems: [
				'Existindo tantas galinhas e pintinhos no mundo é impossível que não apareçam em muitos filmes. Talvez os dois mais famosos sejam “A fuga das galinhas”, na qual fogem de um dono malvado, e “O Galinho Chicken Little”, na qual um franguinho enche de coragem para salvar sua família, amigos e o mundo de una invasão extraterrestre:',
				'Tanto ou mais famosas são as aventuras do galo Frangolino (de Looney Tunes) com o franguinho filho da sua vizinha no galinheiro.',
			],
			starItems: [
				{
					img: star1,
					text: 'Será sua quando você puder imitar o som da galinha.',
				},
				{
					img: star2,
					text: 'Você sabe onde moram as galinhas? Se já souber, a segunda é sua!',
				},
				{
					img: star3,
					text: 'Você a conseguirá quando aprender alguma destas divertidas músicas que ouviu!',
				},
				{
					img: star4,
					text: 'A quarta e última! Você conseguirá quando acabar de colorir o desenho descarregável.',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? chickenMobile : chicken" :img-ratio="isMobile ? 0.76 : 2.17" />
		<DataSection
			section-title="Dados surpreendentes"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondChickenImg"
			:sound-img="soundImg"
			:animal-sound="chickenSound"
			:imgWidth="$vuetify.breakpoint.xs ? '200px' : isMobile ? '300px' : '600px'"
			:img-style="$vuetify.breakpoint.xs ? 'top: 92%' : isMobile ? 'top: 85%' : 'top: 70%'"
			:background="dataBg"
			class="lightpurple py-16"
		/>
		<DataSection
			section-title="Você sabia?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow pt-16"
		/>
		<PortugueseSongSection animal="galinha">
			<iframe
				src="https://open.spotify.com/embed/playlist/0jPA7SuavK3cBWq2TgwG9p"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			></iframe>
		</PortugueseSongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="chickenPic"
			section-title="Na tela grande!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="Torne-se um/a especialista em animais!"
			second-section-title="Já completou as 4 tarefas? Parabéns!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					Se quiser obter seu certificado de especialista em animais deverá cumprir as instruções que os
					animais têm preparado para você. Para poder conseguir cada uma dessas estrelas na área das
					galinhas, terá que realizar estes 4 desafios. Vamos lá!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					É hora de procurar o certificado que acompanha seu livro “1,2,3 O que vê? e pegar seus lápis de
					cor. Pinte a quarta estrela na cor laranja. Você é um/a especialista em galinhas!
				</div>
			</template>
		</StarsSection>
	</div>
</template>
