<script>
// HEADER
import chicken from './assets/chicken-english.jpg'
import chickenMobile from './assets/chicken-mobile-english.jpg'

// DATA SECTION
import secondChickenImg from './assets/chicken-2.png'
import icon from './assets/chicken-icon.png'
import soundImg from './assets/chicken-btn-audio-english.png'
import chickenSound from './assets/chicken-sound.mp4'
import chickenPic from './assets/chicken-pic.png'

// STAR SECTION
import star1 from './assets/chicken-star1.png'
import star2 from './assets/chicken-star2.png'
import star3 from './assets/chicken-star3.png'
import star4 from './assets/chicken-star4.png'
import dataBg from './assets/data-bg.png'

export default {
	pageView: true,
	data() {
		return {
			chicken,
			chickenMobile,
			secondChickenImg,
			icon,
			soundImg,
			chickenSound,
			chickenPic,
			dataBg,
			dataSectionItems: [
				'If chickens are left to be quiet and they are not stolen, a chicken can lay 12 consecutive eggs, then it stops, sits on top of them and it’s time to incubate them!',
				'Some types of chicken are so motherly that they don’t mind incubating eggs that belong to ducks, geese, quails, turkeys and even pheasants. And then they raise them!',
				'Some roosters can be very “gentlemany;” if they find some food, they call hens so they eat first. Roosters sing, pick and spread food all over the place, as if they were chefs very proud of their dishes.',
				'There are three chickens or chicks per each person in the planet. They are the most abundant birds in the world: around 23.000 million.',
			],

			secondDataSectionItems: [
				{
					title: 'Their home is in...',
					text: 'Every continent, except for Antarctica.',
				},
				{
					title: 'The chicken is...',
					text: 'A bird: it is born from an egg, it is covered by feathers and it can fly. Well, actually it can jump spectacularly… if it is allowed.',
				},
				{
					title: 'It likes eating...',
					text: 'Surprise! Chickens are omnivores as people. Normally, they eat grains and insects, but they can swallow lizards and even little mice without any problem.',
				},
			],
			thirdDataSectionItems: [
				'Since there are so many chickens and chicks in the world, it is impossible that they do not appear in a lot of movies. The two most famous are “Chicken Run,” where they escape from an evil owner, and “Chicken Little,” in which a chicken acts very bravely to save his family, his friends and even the world from an alien invasion.',
				'Just as famous, if not more, we have the adventures of the rooster Foghorn Leghorn (Looney Tunes) with the chick, son of his neighbor, in the coop.',
			],
			starItems: [
				{
					img: star1,
					text: 'You will have it when you can copy the sound of the chicken.',
				},
				{
					img: star2,
					text: 'Do you know where chickens live? If you do, the second star is yours!',
				},
				{
					img: star3,
					text: 'You will get it when you learn one of these fun songs.',
				},
				{
					img: star4,
					text: 'The fourth and last! You will get it after you finish coloring the downloadable sheet.',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? chickenMobile : chicken" :img-ratio="isMobile ? 0.76 : 2.17" />
		<DataSection
			section-title="Surprising Facts"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondChickenImg"
			:sound-img="soundImg"
			:animal-sound="chickenSound"
			:imgWidth="$vuetify.breakpoint.xs ? '200px' : isMobile ? '300px' : '600px'"
			:img-style="$vuetify.breakpoint.xs ? 'top: 92%' : isMobile ? 'top: 85%' : 'top: 70%'"
			:background="dataBg"
			class="lightpurple py-16"
		/>
		<DataSection
			section-title="Did You Know It?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow pt-16"
		/>
		<EnglishSongSection animal="chicken">
			<iframe
				src="https://open.spotify.com/embed/playlist/0jPA7SuavK3cBWq2TgwG9p"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			></iframe>
		</EnglishSongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="chickenPic"
			section-title="On the Big Screen!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="Become an Animal Expert!"
			second-section-title="Have you completed the 4 tasks? Congratulations!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					To obtain your certificate of Animal Specialist, you must comply with the tasks that the
					animals have prepared for you. To get each of the stars in the field of chickens, you will have
					to rise to these 4 challenges. Let’s get started!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					It’s time to look for the certificate that comes together with your book “1 2 3 What do you
					see?” and grab your color pencils. Color the fourth star orange. You’re a chicken specialist!
				</div>
			</template>
		</StarsSection>
	</div>
</template>
