<script>
// HEADER
import ant from './assets/ant-english.jpg'
import antMobile from './assets/ant-mobile-english.jpg'

// DATA SECTION
import secondAntImg from './assets/ant-2.png'
import icon from './assets/ant-icon.png'
import antPic from './assets/ant-pic.png'

// STAR SECTION
import star1 from './assets/ant-star1.png'
import star2 from './assets/ant-star2.png'
import star3 from './assets/ant-star3.png'
import star4 from './assets/ant-star4.png'
import dataBg from './assets/data-bg.png'

export default {
	pageView: true,
	data() {
		return {
			ant,
			antMobile,
			secondAntImg,
			icon,
			antPic,
			dataBg,
			dataSectionItems: [
				'Ants cooperate with each other and have jobs and even body shapes specialized for what they do: the ones which look after baby ants, the ones which go hunting, the warriors that defend the nest, the ones which clean it, the ones who reproduce themselves. They act with such a coordination that some people think they are a “superorganism:” an animal made of animals.',
				'Some types of ants have individual teachers! If there is an ant which doesn’t know how to cut leaves, it works together with another which knows and shows how to do it.',
				'Ants, such as any other animal, can also be tricked. There is a type of caterpillar that releases some smell that makes ants believe it is the larva of an ant, so they take it to the colony and they feed it as if it were an ant… until it becomes a butterfly and eats them!.',
			],

			secondDataSectionItems: [
				{
					title: 'Their home is in...',
					text: 'Every continent, except for Antartica and a few remote islands.',
				},
				{
					title: 'The ant is...',
					text: 'An insect.',
				},
				{
					title: 'It likes eating...',
					text: 'Other insects, seeds, nectar from flowers and even mushrooms that it grows itself. That is why it is an omnivore.',
				},
			],
			thirdDataSectionItems: [
				'The most famous movie about ants is “Antz”, in which there is a worker ant called Z who wants to make a princess ant named Bala to fall in love with him. To be honest, it is not so easy, so he goes on many adventures to conquer her heart.',
				'In the USA, there is a very entertaining series in which an anteater (which survives by eating ants) keeps chasing a very thin, weak ant which is also active and a fighter.',
			],
			starItems: [
				{
					img: star1,
					text: 'It will be all yours when you learn 3 surprising facts about ants.',
				},
				{
					img: star2,
					text: 'You will get it when you learn one of these fun songs',
				},
				{
					img: star3,
					text: 'It will be yours if you know what ants like eating.',
				},
				{
					img: star4,
					text: 'The fourth and last! After you finish coloring the downloadable sheet.',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? antMobile : ant" :img-ratio="isMobile ? 0.76 : 2.17" />
		<DataSection
			section-title="Surprising Facts"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondAntImg"
			:imgWidth="$vuetify.breakpoint.xs ? '250px' : isMobile ? '300px' : '600px'"
			:img-style="$vuetify.breakpoint.xs ? 'top: 90%' : isMobile ? 'top: 85%' : 'top: 65%'"
			:background="dataBg"
			class="lightpurple"
		/>
		<DataSection
			section-title="Did You Know It?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow pt-16"
		/>
		<EnglishSongSection animal="ant">
			<iframe
				src="https://open.spotify.com/embed/playlist/3tPHELWWFX6TPzgFpQHBmP"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			></iframe>
		</EnglishSongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="antPic"
			section-title="On the Big Screen!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="Become an Animal Expert!"
			second-section-title="Have you completed the 4 tasks? Congratulations!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					To obtain your certificate of Animal Specialist, you must comply with the tasks that the
					animals have prepared for you. To get each of the stars in the field of ants, you will have to
					rise to these 4 challenges. Let’s get started!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					It’s time to look for the certificate that comes together with your book “1 2 3 What do you
					see?” and grab your color pencils. Color the fifth star violet. You’re an ant specialist!
				</div>
			</template>
		</StarsSection>
	</div>
</template>
