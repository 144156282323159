<script>
// HEADER
import crocodile from './assets/crocodile.png'
import crocodileMobile from './assets/crocodile-mobile.png'

// DATA SECTION
import secondCrocodileImg from './assets/crocodile-2.png'
import icon from './assets/crocodile-icon.png'
import crocodilePic from './assets/crocodile-pic.png'

// STAR SECTION
import star1 from './assets/crocodile-star1.png'
import star2 from './assets/crocodile-star2.png'
import star3 from './assets/crocodile-star3.png'
import star4 from './assets/crocodile-star4.png'
import dataBg from './assets/data-bg.png'

export default {
	pageView: true,
	data() {
		return {
			crocodile,
			crocodileMobile,
			secondCrocodileImg,
			icon,
			crocodilePic,
			dataBg,
			dataSectionItems: [
				'El cocodrilo tiene 80 dientes. Se le caen y los reemplaza ¡cerca de 50 veces a lo largo de su vida! Pero al ratón Pérez ni se le ocurre ir a verlos: ¡se lo comerían!',
				'Los cocodrilos no pueden sudar. Por eso, cuando hace calor, para enfriarse… bostezan. O sea, ¡pierden calor por esa bocaza!',
				'El cocodrilo tiene la mordida más fuerte conocida del mundo animal. Y se sabe que los cocodrilos gigantes prehistóricos ¡mordían el doble de fuerte que un tiranosaurio!',
				'Los cocodrilos de tamaños más grandes se tragan piedras intencionalmente para usarlas como lastre (como si fueran barcos) y equilibrarse en el agua. Las usan igualmente para ayudar a moler las partes duras de sus presas. ',
			],

			secondDataSectionItems: [
				{
					title: 'Su hogar está en...',
					text: 'Todos los continentes, menos en Europa. ',
				},
				{
					title: 'El cocodrilo es...',
					text: 'Un reptil, como las lagartijas: nace de un huevo; su piel es seca, hecha de escamas; y la temperatura de su cuerpo depende de la externa totalmente. Por eso no pueden habitar en los polos.',
				},
				{
					title: 'Le gusta comer...',
					text: 'Carne; el cocodrilo es carnívoro. Se alimenta de cualquier animal, excepto insectos. No sabemos por qué, pero algunos comen frutas.',
				},
			],
			thirdDataSectionItems: [
				'Hace no tanto tiempo, los ríos y mares tropicales estaban llenos de cocodrilos, y no son muy amistosos que digamos, los cocodrilos suelen ser los “malos” en las películas. En “Peter Pan”, el cocodrilo es malito, pero simpático, porque, a su vez, es el enemigo del malvado capitán Garfio.',
				'Hay un cortometraje animado donde un cocodrilo es mascota y bueno. Tan bueno, que representa la timidez de una chica que no se atreve a hablarle a su vecino.',
			],
			starItems: [
				{
					img: star1,
					text: 'La obtendrás cuando puedas cantar una canción de los cocodrilos.',
				},
				{
					img: star2,
					text: 'Será tuya cuando tu lámina descargable esté ¡súper coloreada!',
				},
				{
					img: star3,
					text: 'Toda para ti cuando sepas dónde viven los cocodrilos.',
				},
				{
					img: star4,
					text: 'La cuarta y ¡última! La tendrás cuando recuerdes cuántos dientes tiene el cocodrilo. ',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? crocodileMobile : crocodile" />
		<DataSection
			section-title="Datitos sorprendentes"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondCrocodileImg"
			:imgWidth="$vuetify.breakpoint.xs ? '250px' : isMobile ? '350px' : '650px'"
			:img-style="$vuetify.breakpoint.xs && 'top: 85%'"
			:background="dataBg"
			class="lightpurple py-16"
		/>
		<DataSection
			section-title="¿Lo sabías?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow pt-16"
		/>
		<SongSection animal="cocodrilo">
			<iframe
				src="https://open.spotify.com/embed/playlist/4e1BZVXJDOcfXT4a4STTBZ"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			/>
		</SongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="crocodilePic"
			section-title="¡En la pantalla grande!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="¡Conviértete en un/a especialista en animales!"
			second-section-title="¿Has completado las 4 tareas? ¡Felicitaciones!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					Si quieres obtener tu certificado de especialista en animales deberás cumplir las consignas que
					los animales han preparado para ti. Para poder conseguir cada una de estas estrellas en el área
					de los cocodrilos, tendrás que realizar estos 4 desafíos. ¡Comencemos!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					Es hora de buscar el certificado que viene con tu libro “1, 2, 3 ¿Qué ves?” y sacar tus lápices
					de colores. Pinta la segunda estrella de color verde. ¡Eres un/a especialista en cocodrilos!
				</div>
			</template>
		</StarsSection>
		<Home-G-Cards />
	</div>
</template>
