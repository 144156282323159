<script>
// HEADER
import octopus from './assets/octopus.png'
import octopusMobile from './assets/octopus-mobile.png'

// DATA SECTION
import secondOctopusImg from './assets/octopus-2.png'
import icon from './assets/octopus-icon.png'
import octopusPic from './assets/octopus-pic.png'

// STAR SECTION
import star1 from './assets/octopus-star1.png'
import star2 from './assets/octopus-star2.png'
import star3 from './assets/octopus-star3.png'
import star4 from './assets/octopus-star4.png'
import dataBg from './assets/data-bg.png'

import customBg from './assets/data-bg-2-octopus.png'

export default {
	pageView: true,
	data() {
		return {
			customBg,
			octopus,
			octopusMobile,
			secondOctopusImg,
			icon,
			octopusPic,
			dataBg,
			dataSectionItems: [
				'El pulpo tiene ocho piernas. ¡Imagínense qué cosas harían ustedes si tuvieran esas ocho piernas y pudieran usarlas también como brazos!',
				'Los pulpos son muy buenos para disfrazarse: como son tan blandos, y no tienen huesos, pueden tomar la forma y el color de otros animales. Así se hacen pasar por serpientes de mar, anguilas eléctricas y el pez león. Entonces, sus enemigos se asustan y se van.',
				'Cada pulpo tiene una bolsita en su cuerpo llena de tinta. Cuando tienen miedo, la disparan detrás de ellos y se escapan nadando muy rápido.',
				'Existen dos tipos de pulpos ¡que pueden caminar como nosotros! Usan dos de sus piernas, mientras que las otras seis las mueven como algas para hacer creer a los peces que ellos son plantas submarinas. ',
			],

			secondDataSectionItems: [
				{
					title: 'Su hogar está en...',
					text: 'Todos los mares, desde aguas superficiales hasta aguas muy profundas. Pero no en lagos ni ríos.',
				},
				{
					title: 'El pulpo es...',
					text: 'Un molusco, igual que los caracoles y las ostras.',
				},
				{
					title: 'Le gusta comer...',
					text: 'Almejas, cangrejos, centollas y otros crustáceos. Algunos pulpos comen gambas y peces. Por eso es carnívoro.',
				},
			],
			thirdDataSectionItems: [
				'Las personas, cuando crecen, les tienen miedo a los animales que son distintos. En especial, si se dan cuenta de que son inteligentes. Eso pasa con el pulpo: lo ponen de “malo” en las series y películas. Por ejemplo, en “Pingüinos de Madagascar”, el villano es Dave, un pulpo celoso porque los pingüinos le quitaron el protagonismo en el zoológico.',
				'En la vida real hay una historia muy linda. Un señor que estaba triste, sin poder hablar mucho ni decir cómo se sentía, se hizo amigo de un pulpo (en realidad, era una pulpo), ¡pero amigo en serio! Ahí, debajo del mar. Y gracias a eso se mejoró, y después hizo una película para contar la historia. Se llama “Mi maestro el pulpo”.',
			],
			starItems: [
				{
					img: star1,
					text: 'La tendrás cuando colorees por completo la lámina descargable.',
				},
				{
					img: star2,
					text: 'Será tuya cuando conozcas dónde viven los pulpos.',
				},
				{
					img: star3,
					text: 'La conseguirás cuando aprendas alguna de las lindas canciones que escuchaste.',
				},
				{
					img: star4,
					text: 'La cuarta y ¡última! Cuando puedas imitar con tus brazos el movimiento de sus tentáculos (o brazos).',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? octopusMobile : octopus" :img-ratio="isMobile ? 0.76 : 2.17" />
		<DataSection
			section-title="Datitos sorprendentes"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondOctopusImg"
			:imgWidth="$vuetify.breakpoint.xs ? '225px' : isMobile ? '300px' : '600px'"
			:img-style="$vuetify.breakpoint.xs ? 'top: 90%' : isMobile ? 'top: 80%' : 'top: 55%'"
			:background="dataBg"
			class="lightpurple"
		/>
		<DataSection
			section-title="¿Lo sabías?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow pt-16"
		/>
		<SongSection animal="pulpo" :custom-bg="customBg">
			<iframe
				src="https://open.spotify.com/embed/playlist/6tq5gmi1NaziKwDaYCV95k"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			></iframe>
		</SongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="octopusPic"
			section-title="¡En la pantalla grande!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="¡Conviértete en un/a especialista en animales!"
			second-section-title="¿Has completado las 4 tareas? ¡Felicitaciones!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					Si quieres obtener tu certificado de especialista en animales deberás cumplir las consignas que
					los animales han preparado para ti. Para poder conseguir cada una de estas estrellas en el área
					de los pulpos, tendrás que realizar estos 4 desafíos. ¡Comencemos!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					Es hora de buscar el certificado que viene con tu libro “1, 2, 3 ¿Qué ves?” y sacar tus lápices
					de colores. Pinta la tercera estrella de color celeste. ¡Eres un/a especialista en pulpos!
				</div>
			</template>
		</StarsSection>
		<Home-G-Cards />
	</div>
</template>
