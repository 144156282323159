<script>
// HEADER
import crocodile from './assets/crocodile-portuguese.jpg'
import crocodileMobile from './assets/crocodile-mobile-portuguese.jpg'

// DATA SECTION
import secondCrocodileImg from './assets/crocodile-2.png'
import icon from './assets/crocodile-icon.png'
import crocodilePic from './assets/crocodile-pic.png'

// STAR SECTION
import star1 from './assets/crocodile-star1.png'
import star2 from './assets/crocodile-star2.png'
import star3 from './assets/crocodile-star3.png'
import star4 from './assets/crocodile-star4.png'
import dataBg from './assets/data-bg.png'

export default {
	pageView: true,
	data() {
		return {
			crocodile,
			crocodileMobile,
			secondCrocodileImg,
			icon,
			crocodilePic,
			dataBg,
			dataSectionItems: [
				'O crocodilo tem 80 dentes. Eles caem e são substituídos por volta de 50 vezes ao longo da sua vida! Mas a Fada do Dente nem pensa em fazer uma visita para recolhê-los: a comeriam!',
				'Os crocodilos não podem suar. Por isso, quando está quente, para se esfriarem… bocejam. Isto é, perdem calor por essa bocaça!',
				'Um crocodilo tem a mordida mais forte conhecida do mundo animal. E sabe-se que os crocodilos gigantes pré-históricos, mordiam o dobro de forte que um tiranossauro!',
				'Os crocodilos de tamanhos maiores engolem pedras intencionalmente para usá-las como lastro (como se fossem navios) e se equilibrarem na água. As utilizam igualmente para ajudar a moer as partes duras de suas presas.',
			],
			secondDataSectionItems: [
				{
					title: 'Seu lar está em…',
					text: 'Todos os continentes, menos na Europa',
				},
				{
					title: 'O crocodilo é...',
					text: 'Um réptil, como as lagartixas: nasce de um ovo; sua pele é seca, feita de escamas; e a temperatura de seu corpo depende da externa totalmente. Por isso não podem habitar nos polos.',
				},
				{
					title: 'Gosta de comer…',
					text: 'Carne; o crocodilo é carnívoro. Alimenta-se de qualquer animal, exceto insetos. Não sabemos o porquê, mas alguns comem frutas',
				},
			],
			thirdDataSectionItems: [
				'Há não tanto tempo, os rios e mares tropicais estavam cheios de crocodilos, e não são muito amigáveis certamente, os crocodilos costumam ser os “maus” nos filmes. Em “Peter Pan”, o crocodilo é mauzinho, porém simpático, porque, por sua vez, é o inimigo do malvado capitão Gancho',
				'Há uma curta-metragem animada na qual um crocodilo é um bichinho de estimação e bom. Tão bom, que representa a timidez de uma menina que não se atreve a falar com seu vizinho.',
			],
			starItems: [
				{
					img: star1,
					text: 'Será conseguida quando você puder cantar uma música dos crocodilos.',
				},
				{
					img: star2,
					text: 'Será sua quando seu desenho descarregável estiver super colorido!',
				},
				{
					img: star3,
					text: 'Toda para você quando souber onde moram os crocodilos.',
				},
				{
					img: star4,
					text: 'A quarta e última! a conseguirá quando lembrar da quantidade de dentes que o crocodilo tem.',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? crocodileMobile : crocodile" />
		<DataSection
			section-title="Dados surpreendentes"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondCrocodileImg"
			:imgWidth="$vuetify.breakpoint.xs ? '250px' : isMobile ? '350px' : '650px'"
			:img-style="$vuetify.breakpoint.xs && 'top: 85%'"
			:background="dataBg"
			class="lightpurple py-16"
		/>
		<DataSection
			section-title="Você sabia?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow pt-16"
		/>
		<PortugueseSongSection animal="crocodilo">
			<iframe
				src="https://open.spotify.com/embed/playlist/4e1BZVXJDOcfXT4a4STTBZ"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			/>
		</PortugueseSongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="crocodilePic"
			section-title="Na tela grande!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="Torne-se um/a especialista em animais!"
			second-section-title="Já completou as 4 tarefas? Parabéns!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					Se quiser obter seu certificado de especialista em animais deverá cumprir as instruções que os
					animais têm preparado para você. Para poder conseguir cada uma dessas estrelas na área dos
					crocodilos, terá que realizar estes 4 desafios. Vamos lá!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					É hora de procurar o certificado que acompanha seu livro “1,2,3 O que vê? e pegar seus lápis de
					cor. Pinte a segunda estrela na cor verde. Você é um/a especialista em crocodilos!
				</div>
			</template>
		</StarsSection>
	</div>
</template>
