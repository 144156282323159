<script>
export default {
	props: {
		sectionTitle: String,
		sectionNumber: String,
		items: [],
		icon: String,
		title: Boolean,
		img: String,
		makeSound: Boolean,
		soundImg: String,
		imgWidth: String,
		background: String,
		animalSound: String,
		rowClass: String,
		imgStyle: String,
	},
	data() {
		return {
			play: false,
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
	watch: {
		play(v) {
			v ? this.$refs.animal.play() : this.$refs.animal.pause()
		},
	},
}
</script>
<template>
	<div style="position: relative">
		<Media :src="background" width="100%" style="position: absolute" cover />
		<Container>
			<v-row :class="rowClass">
				<v-col cols="12" md="6" style="z-index: 13">
					<div class="mb-10">
						<div class="font-10 font-weight-bold number">.{{ sectionNumber }}</div>
						<div class="font-4 font-weight-bold">{{ sectionTitle }}</div>
					</div>
					<div
						v-for="(item, i) of items"
						:key="`dataSectionItems-${i}`"
						class="d-flex align-start mb-8 px-md-10 pr-10"
					>
						<Media v-if="icon" :src="icon" contain max-width="30px" />
						<div class="d-flex flex-column ml-5" v-if="title">
							<span class="font-weight-bold">{{ item.title }}</span>
							<span>{{ item.text }}</span>
						</div>
						<span class="ml-5" v-else>{{ item }}</span>
					</div>
				</v-col>
				<v-col cols="12" md="6" class="d-flex justify-center">
					<div
						:class="{
							'absolute-img-xs': $vuetify.breakpoint.xs,
							'absolute-img-mobile': isMobile,
							'absolute-img': !isMobile,
						}"
						v-if="img"
						:style="imgStyle"
					>
						<Media :src="img" :width="imgWidth" contain />
					</div>
				</v-col>
			</v-row>
			<div class="absolute-left-img d-flex">
				<Media
					:src="soundImg"
					contain
					:width="isMobile ? '100px' : '130px'"
					@click="play = !play"
					class="pointer"
				/>
				<audio :src="animalSound" ref="animal" :controls="play"></audio>
			</div>
		</Container>
	</div>
</template>

<style lang="scss" scoped>
.number {
	color: #f17420;
}
.absolute-img-mobile {
	position: absolute;
	bottom: -10rem;
	right: 0;
	z-index: 10;
}
.absolute-img {
	position: absolute;
	top: 55%;
	right: 0;
	z-index: 10;
}
.absolute-left-img {
	position: absolute;
	bottom: -50px;
	z-index: 12;
}
</style>
