<script>
// HEADER
import crocodile from './assets/crocodile-english.jpg'
import crocodileMobile from './assets/crocodile-mobile-english.jpg'

// DATA SECTION
import secondCrocodileImg from './assets/crocodile-2.png'
import icon from './assets/crocodile-icon.png'
import crocodilePic from './assets/crocodile-pic.png'

// STAR SECTION
import star1 from './assets/crocodile-star1.png'
import star2 from './assets/crocodile-star2.png'
import star3 from './assets/crocodile-star3.png'
import star4 from './assets/crocodile-star4.png'
import dataBg from './assets/data-bg.png'

export default {
	pageView: true,
	data() {
		return {
			crocodile,
			crocodileMobile,
			secondCrocodileImg,
			icon,
			crocodilePic,
			dataBg,
			dataSectionItems: [
				'Crocodiles have 80 teeth. They fall out and get replaced around 50 times during the crocodile’s life! But the Tooth Fairy doesn’t even consider visiting them: they would eat her!',
				'Crocodiles can’t sweat. For that reason, when it’s hot, in order to get cold… they yawn. In other words, they lose some heat through that huge mouth!',
				'Crocodiles have the strongest bite in the whole animal world. And it is known that huge prehistoric crocodiles could bite twice as hard as a tyrannosaurus!',
				'The largest crocodiles swallow stones intentionally to use them as ballast (as if they were boats) and to have balance inside the water. They also use them to help them crush the hard parts of their pray.',
			],
			secondDataSectionItems: [
				{
					title: 'Their home is in...',
					text: 'Every continent, except for Europe. In Argentina, you can find their cousin, a caiman named “yacaré.” It looks very similar, but it’s not the same.',
				},
				{
					title: 'The crocodile is...',
					text: 'A reptile, like lizards: it is born from an egg; its skin is dry, made of scales; and the temperature of its body depends entirely on the external temperature. That is why they cannot live in the poles.',
				},
				{
					title: 'It likes eating...',
					text: 'Meat; the crocodile is a carnivore. It eats all of the animals, except for insects. We don’t know why, but some of them eat fruit',
				},
			],
			thirdDataSectionItems: [
				'Not so long ago, rivers and tropical seas were full of crocodiles, and they are not very friendly. Crocodiles are usually the “bad” characters in movies. In “Peter Pan,” the crocodile is quite bad but, at the same time, it is nice because it’s the enemy of the evil Captain Hook.',
				'There is an animated short film where a crocodile is a pet and it is good. So good that it represents the shyness of a girl who is not brave enough to talk to her neighbor. ',
			],
			starItems: [
				{
					img: star1,
					text: 'You will get it when you can sing a song about crocodiles.',
				},
				{
					img: star2,
					text: 'It will be yours when your downloadable sheet is really colored!',
				},
				{
					img: star3,
					text: 'All yours when you know where crocodiles live.',
				},
				{
					img: star4,
					text: 'The fourth and last! You will have it when you remember how many teeth the crocodile has.',
				},
			],
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="page-bg-container" style="overflow: hidden">
		<AnimalHeader :img="isMobile ? crocodileMobile : crocodile" />
		<DataSection
			section-title="Surprising Facts"
			:items="dataSectionItems"
			:icon="icon"
			sectionNumber="01"
			:img="secondCrocodileImg"
			:imgWidth="$vuetify.breakpoint.xs ? '250px' : isMobile ? '350px' : '650px'"
			:img-style="$vuetify.breakpoint.xs && 'top: 85%'"
			:background="dataBg"
			class="lightpurple py-16"
		/>
		<DataSection
			section-title="Did You Know It?"
			:items="secondDataSectionItems"
			title
			:icon="icon"
			sectionNumber="02"
			class="lightyellow pt-16"
		/>
		<EnglishSongSection animal="crocodile">
			<iframe
				src="https://open.spotify.com/embed/playlist/4e1BZVXJDOcfXT4a4STTBZ"
				width="300"
				height="380"
				frameborder="0"
				allowtransparency="true"
				allow="encrypted-media"
			/>
		</EnglishSongSection>
		<AnimalPicSection
			:text-items="thirdDataSectionItems"
			:animal-img="crocodilePic"
			section-title="On the Big Screen!"
		/>
		<StarsSection
			:star-items="starItems"
			section-title="Become an Animal Expert!"
			second-section-title="Have you completed the 4 tasks? Congratulations!"
		>
			<template v-slot:first-text>
				<div class="my-10">
					To obtain your certificate of Animal Specialist, you must comply with the tasks that the
					animals have prepared for you. To get each of the stars in the field of crocodiles, you will
					have to rise to these 4 challenges. Let’s get started!
				</div>
			</template>
			<template v-slot:second-text>
				<div class="font-weight-bold">
					It’s time to look for the certificate that comes together with your book “1 2 3 What do you
					see?”and grab your color pencils. Color the second star green. You’re a crocodile specialist!
				</div>
			</template>
		</StarsSection>
	</div>
</template>
